import { createSlice } from "@reduxjs/toolkit";

import { usermanagement } from "../Actions/AdminActions";


export const usermanageSlice = createSlice({
    name: "usermanageSlice",
    initialState:{
        alldata: "",
    },
    reducers: {
          
    },

    extraReducers: (builder) => {
        
        builder.addCase(usermanagement.fulfilled, (state, action) => {
            state.alldata = action.payload.data.data;
            
        });
    }
    
})


export default usermanageSlice.reducer;