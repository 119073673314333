import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { forgetPass } from "../redux/Actions/AdminActions";

const validationschema = Yup.object().shape({
  email: Yup.string()
    .email(<span className="error-message">Invalid Email Address</span>)
    .required(<span className="error-message">Required</span>),
});

export default function ForgotPassword() {
  const dispatch = useDispatch();

   const handleForgetPass = (values) => {
    dispatch(forgetPass(values)).then((res) => {
      console.log(res);
      if(res?.payload?.data?.status === 200){
        toast.success(res?.payload?.data?.message)
      }else{
        toast.error(res?.payload?.data?.message)
      }
    }).catch((err)=> {
      console.log(err,"error occur");
    })
   }
  return (
    <div>
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="logo">
              <img src={require("../Assets/Images/Logo.svg").default} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="">
        <div className="row  sign-banner-part">
          <Col lg={8} className="p-0">
            <div className="otp-banner-img"></div>
          </Col>
          <Col lg={4}>
            <div className="right-banner-part">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap">
                  <h2>Forgot Passwoard 🔒</h2>
                  <p className="mb-0">
                    Enter your email and we'll send you instructions to reset
                    your password
                  </p>
                  <Formik initialValues={{ email:"" }}
                  validationSchema={validationschema}
                  onSubmit={handleForgetPass}>
                    {({values,handleSubmit,handleChange}) => (
                      <Form >
                        <div className="form-set">
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Email</Form.Label>
                            <Field
                             as={Form.Control}
                              type="email"
                              placeholder="Enter your email"
                              name="email"
                              value={values?.email}
                              onChange={handleChange}
                            />
                            <ErrorMessage name="email" component="div"/>
                          </Form.Group>
                        </div>
                        <div onClick={handleSubmit}>  
                        <Link
                          // to="/reset-password"
                          variant="primary"
                          type="submit"
                          className="submit forgot-btn"
                        >
                          Send Reset Link
                        </Link>                    
                        </div>
                        <div className="back-link-ad">
                          <Link to="/">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7"
                              height="13"
                              viewBox="0 0 7 13"
                              fill="none"
                            >
                              <path
                                d="M6 1.5L1 6.5L6 11.5"
                                stroke="#7367F0"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            Back to login
                          </Link>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </Container>
    </div>
  );
}
