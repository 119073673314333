import { createAsyncThunk } from "@reduxjs/toolkit";
import { AdminAPI } from "../../services/AdminApi";

// ====== For Usermanagement =====

export const usermanagement = createAsyncThunk(
    "usermanagement",
    async (details) => {
      let url = `/userManagement?page=${details?.page}&limit=${details?.limit}`;
  
      if (details.search) {
        url += `&name=${details.search}`;
      }
  
      const data = await AdminAPI.get(url);
      console.log(data);
      return data;
    }
  );


  // ======== Delete User account api =============

export const DeleteUser = createAsyncThunk(
    "DeleteUser",
    async(details) => {
        const data = await AdminAPI.delete(`/deleteAccount?userId=${details?.id}`);
       return data;
    } 
  );

// ===== Forget Password =======

export const forgetPass = createAsyncThunk(
  "forgetPass",
  async(details) => {
      const data = await AdminAPI.post(`/forgetpassword`,details);
     return data;
  } 
);

// ======= Reset Password ===========

export const ResetPass = createAsyncThunk(
  "ResetPass",
  async(details) => {
      const data = await AdminAPI.post(`/resetpassword`,details);
     return data;
  } 
);

// ==================== change Password ======================

export const chnagePassword = createAsyncThunk(
  "chnagePassword",
  async (details) => {
    const data = await AdminAPI.patch(`/changePassword`, details);
    console.log(data);
    return data;
  }
);

// ==================== edit admin profile ======================

export const editAdminprofile = createAsyncThunk(
  "editAdminprofile",
  async (details) => {
    const data = await AdminAPI.put(`/editProfile`, details);
    console.log(data);
    return data;
  }
);

// ==== get admin details to edit profile ====
export const getAdmindetails = createAsyncThunk(
  "getAdmindetails",
  async () => {
    const data = await AdminAPI.get(`/getProfile`);
    console.log(data);
    return data;
  }
);


// ===== Get user Profile =====

export const getuserprofile = createAsyncThunk(
  "getuserprofile",
  async (details) => {
    const data = await AdminAPI.get(`/userProfile?userId=${details?.userId}`);
    console.log(data);
    return data;
  }
);


// ================ get user activity ===================

export const  getActivity = createAsyncThunk(
  "getActivity",
  async(details)=> {
     const {data} = await AdminAPI.get(`/getActivities?user_id=${details?.id}`)
     return data;
  }
);


// =============== Activate or deactivate user =================

export const Suspenduser = createAsyncThunk(
  "Suspenduser",
  async (details) => {
    const data = await AdminAPI.put(`/activateDeactivateUser`, details);
    console.log(data);
    return data;
  }
);